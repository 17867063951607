

.driver{
    margin: 5px;
    display: flex;
    flex-direction: column;    
    justify-content: center;
    border: 1px solid black;
    width: 260px;
    min-height: 300px;
    max-height: 400px;    
    
}
