

.receipts{
    border: 1px solid black;
    min-width: 400px;
    max-width: 400px;
    border-radius: 10px;
    background-color: blanchedalmond;
    display: flex; 
        
    
    p{
        width: 50%;
    }
}