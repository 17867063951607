


.driver-marker {    
  display: block;    
  border-radius: 50%;   
  background-size: cover;
  width: 40px;
  height: 40px;
  cursor: pointer;
    }