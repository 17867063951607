
@use '../styles/variables' as v;


button{
    border-radius: 20px;
}
main{

}
.welcome{
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
        width: 400px;
        
    }
    img{
        max-height: 300px;        
    }
}
.welcome-inst{
    border: 2px solid black;
    display: flex;
    width: 500px;
    font-size: 1.5rem;
    margin: 5px;   
}
#register-form{
    width: 500px;
}