@use './variables' as v;

form{
    min-width: 100px;
    max-width: 150px;    
    display: flex;
    flex-direction: column;
    justify-content: center;    
    text-align: center;    
    border-radius: 10px;
    background-color: blanchedalmond;   
    
    
}
.action-img{    
        max-height: 30px;
        max-width: 30px;           
}
.profile-pic{
    max-height: 200px;
    max-width:  200px;
    border-radius: 50%;
    min-height: 200px;
    min-width: 200px;
}
#login{
    background-color: v.$accent2;
}
#begin{
    background-color: v.$accent1;
}
#register{
    background-color: v.$accent1;
}
#logout{
    background-color: v.$accent2;
}
#add-driver{    
    background-color: v.$accent1;
    height: 50px;
    width: 150px;
}
#submit-driver{
    background-color: v.$accent2;
    height: 50px;
    width: 150px;
}
#options{
    background-color: v.$accent1;
}
#start-trip{
    background-color: v.$accent2;
    height: 50px;
    width: 150px;
}
#add-receipts{
    background-color: v.$accent1;
    height: 50px;
    width: 150px;
}
#submit-receipt{
    background-color: v.$accent2;
    height: 50px;
    width: 150px;
}