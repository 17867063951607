@use '../styles/variables';

.trips-display{
    display: flex;
    flex-direction: column;
    align-items: center;    
    
   
    main{              
        font-size: larger;        
        img{
            border-radius: 50%;
            max-height: 100px;

        }  
        background-color: rosybrown;
        border: 1px black solid;
        display: flex;
        flex-direction: column;      
    }
    
    
    h5{
        position: absolute;
        top: 20px;
        left: 100px;   
        
    }
    
}
.trip-title{    
    display: flex;
    justify-content: space-between;
         h4{
             width: 100%;
         }
    }
.display-pie-chart{
    height: 150px;
    width: 150px;   
        
}   
.display-bar-chart{
    height: 200px;
    width: 450px;  
    position: relative;
    
}
.trip-info{
    display: flex;
    flex-direction: column;
    }
// #begin{
//     background-color: v.$accent3;
// }  

.select-form{
    
}