@use '../styles/variables';
.trip-display{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    button{
        width: 100px;
    }
    .pie-chart{
    height: 250px;
    max-width: 350px;
    min-width: 350px;    
}     
    .bar-chart{
    height: 250px;
    max-width: 450px;
    min-width: 450px;  
    }
}
.quick-add{
    margin: 10px 0 10px 0;
    width: 400px;
    display: flex;
    justify-content: space-evenly;
}