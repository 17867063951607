.main-logo{
    max-height: 50px;
    max-width: 200px;
}
header{
    display: flex;
    justify-content: space-between;
    button{
        height: 25px;        
    }
    text-align: center;
}

@media(min-width:800px){
    .main-logo{
        max-height: 50px;
        max-width: 200px;
    }
 header{
     justify-content: space-evenly;
 }
}
